import axios from 'axios';
import api from './api';
import { loadStripe } from '@stripe/stripe-js';
// eslint-disable-next-line import/no-cycle
import { logged } from './security.service';
import { isArray, isObject } from '../helpers/javascript';
import { registerRequest } from '../helpers/browser';
import plansDescriptions from './plans.json';
import { API_HOST } from '~/services/app.config';
import useSWR from 'swr';
import { useAuthentication } from '~/hooks/useAuthentication';
import { useCallback } from 'react';
import { useTeam } from './team.services';

const stripePromise = loadStripe(process.env.STRIPE_PK);

const redirectToCheckout = async sessionId => {
  const stripe = await stripePromise;
  // eslint-disable-next-line no-return-await
  return await stripe.redirectToCheckout({ sessionId });
};

export function usePlan() {
  const { user } = useAuthentication();
  const { id: teamId } = useTeam();
  const {
    data: plan,
    mutate,
    ...rest
  } = useSWR(
    user && teamId ? [`${API_HOST}/custom/payments/plan`, user.username, teamId] : null,
    ([url]) => {
      return api({
        url,
        method: 'POST'
      })
        .then(response => {
          if (isObject(response.data) && response.data.success) {
            return { ...response.data.success, userOwner: response.data.userOwner };
          } else {
            return false;
          }
        })
        .catch(e => {
          return false;
        });
    },
    {
      fallbackData:
        typeof localStorage != 'undefined' ? JSON.parse(localStorage.getItem('plan')) : null,
      onSuccess: data => {
        localStorage.setItem('plan', JSON.stringify(data));
      }
    }
  );

  const updateProWidgets = useCallback(
    count => {
      mutate(
        {
          ...plan,
          currentProWidgets: count
        },
        { revalidate: false }
      );
    },
    [mutate, plan]
  );

  return { plan, updateProWidgets, ...rest };
}

export const session = ({ price, type = 'stripe', category }) => {
  let successUrl = null;
  if (category === 'video') {
    const user = logged();
    successUrl = `${window.location.origin}/processing/paid/${
      user._id
    }?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`;
  }
  api({
    url: `${API_HOST}/custom/payments/session`,
    method: 'POST',
    data: { url: window.location.href, price, type, successUrl }
  }).then(response => {
    if (isObject(response.data) && response.data.session) {
      if (response.data.type === 'checkout') redirectToCheckout(response.data.session.id);
      else window.location.href = response.data.session.url;
    }
  });
};

export const prices = ({ category }) =>
  new Promise(resolve => {
    let result = null;
    if (isArray(result)) resolve(result);
    else {
      axios({
        url: `${process.env.NEXT_PUBLIC_WORKERS_API}/plans/?category=${category}`,
        method: 'GET'
      })
        .then(response => {
          if (isArray(response.data)) {
            const toResolve = response.data
              .sort((a, b) => {
                if (a.price > b.price) return 1;
                if (b.price > a.price) return -1;
                return 0;
              })
              .map(plan => {
                const planDescription = plansDescriptions[category]?.find(
                  description => description.type === plan.type
                );
                return {
                  ...plan,
                  ...planDescription
                };
              });
            resolve(toResolve);
          } else {
            console.error('Result is not an array');
            resolve([]);
          }
        })
        .catch(error => {
          console.error(error);
          resolve([]);
        });
    }
  });

export const appsumoPrices = () =>
  new Promise(resolve => {
    let result = null;
    if (isArray(result)) resolve(result);
    else {
      const cancelToken = registerRequest('appsumo-products');
      axios({
        url: `${API_HOST}/custom/payments/appsumoProducts`,
        method: 'GET',
        cancelToken
      })
        .then(response => {
          if (isArray(response.data)) {
            const toResolve = response.data.sort((a, b) => {
              if (a.price > b.price) return 1;
              if (b.price > a.price) return -1;
              return 0;
            });
            resolve(toResolve);
          } else resolve([]);
        })
        .catch(() => {
          resolve([]);
        });
    }
  });

export const manageCart = ({ status }) =>
  new Promise(() => {
    api({
      method: 'POST',
      url: `${API_HOST}/custom/payments/manageCart?status=${status}`
    });
  });
